import * as React from "react";
import style from "./sidebar.module.scss";
import { getLogoSrc } from "helpers/transactionsHelper";
import { LabelHelper } from "helpers/labelHelper";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

type Props = {
  lang: string;
};

const Sidebar = ({ lang }: Props) => {
  return (
    <>
      <div className={style.tenantLogoContainer}>
        <ReactSVG
          className={style.logo + " my-2 mt-4"}
          id="logo"
          src={getLogoSrc("BOLT")}
        />
      </div>

      <div className={style.sidebarMenu + " ms-4"}>
        {LabelHelper(lang).SIDEBAR.MENU_ITEMS.map((item, index) => (
          <h4
            className={style.menuItem + " mb-3 ms-2 d-flex"}
            key={"menuItem " + index}
          >
            <ReactSVG src={item.LOGO} />
            <Link to={item.PATH}>{item.TITLE}</Link>
          </h4>
        ))}
      </div>
    </>
  );
};

export default Sidebar;
